import api from '@/services/Api'

export default {
    header () { // En-tête
        return api().get('/dashboard/header')
    },
    graph () { // Graphique 1
        return api().get('/dashboard/graph')
    },
    graphBar () { // Graphique 1
        return api().get('/dashboard/graphBar')
    },
    graphLine () { // Graphique 1
        return api().get('/dashboard/graphLine')
    },
}
