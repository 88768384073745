<template>
  <v-container fluid>
    <!-- En-tête-->
    <h2 v-if="header.clientContrat">
      {{ $t('dashboard.For_period') }} {{ header.factureEleve.periode }}
    </h2>

    <v-row
      v-if="header.clientContrat"
      justify="center"
      no-gutters
    >
      <!-- Clients et Contrats-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <!-- Clients-->
            <div>
              <h1 class="blue--text">
                {{ header.clientContrat.clientsActif }}
              </h1>
            </div>
            <div class="mt-1">
              <span>Clients</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('Active') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Contrat-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <!-- Contrats-->
            <div>
              <h1 class="blue--text">
                {{ header.clientContrat.contratsActif }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('Contract') }}s</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('Active') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.numbreFacturePeriode.facturesPeriode }}
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.numbreFacturePeriode.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.Bill') }}s </span>
            </div>
            <div class="caption grey--text font-weight-light mt-1">
              <span>{{ header.numbreFacturePeriode.periode }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures impayés-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.factureImpaye.resultImpayees }}
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.factureImpaye.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Unpaid_invoices') }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Factures Élevé-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.factureEleve.facturesElevees }}
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">{{ header.factureEleve.total.toFixed(2) }} $</span>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.Bill') }}s > {{ header.factureEleve.parametre }} $</span>
            </div>
            <div class="mt-1">
              <span class="caption grey--text font-weight-light">{{ header.factureEleve.periode }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Commandes-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.commandeEnCours.commandesEnCours }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('sidebar.order') }}</span>
            </div>
            <div>
              <span class="caption grey--text font-weight-light">
                {{ $t('In_progress') }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Profit et frais de retard-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text>
            <div>
              <h1 class="blue--text">
                {{ header.profit.profit_brut }} $
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('contract.bill.Administrative_costs') }}</span>
            </div>
            <div class="caption grey--text font-weight-light mt-1">
              <span>{{ header.profit.periode }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Payments en retard-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.facture90Jours.count }}
              </h1>
            </div>
            <div class="mt-1">
              <span class="blue--text">
                {{ header.facture90Jours.total.toFixed(2) }} $
              </span>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Late_payment') }}</span>
            </div>
            <span class="caption grey--text font-weight-light">
              {{ $t('dashboard.90_days') }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Mauvais payeur-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.mauvaisPayeur }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Bad_payer') }} </span>
            </div>
            <div class="mt-1">
              <span class="caption grey--text font-weight-light">2+ {{ $t('dashboard.Late_payment') }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Suspendus-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <div>
              <h1 class="blue--text">
                {{ header.contratSuspendu }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Suspended_contracts') }} </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Ex employés-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight">
          <v-card-text v-if="$company === 'uqam'">
            <!-- Ex employé-->
            <div>
              <h1 class="blue--text">
                {{ header.exEmploye }}
              </h1>
            </div>
            <div class="mt-1">
              <span>{{ $t('dashboard.Ex_employee') }}</span>
            </div>
            <!-- C2 hors-limite-->
            <div class="mt-1">
              <span>{{ $t('dashboard.C2_Out_of_limit') }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Vide-->
      <v-col
        cols="12"
        lg="2"
      >
        <v-card :height="cardHeight" />
      </v-col>
    </v-row>

    <!-- Graphique beigne-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="pie"
          color="info"
          icon="mdi-chart-pie"
          class="px-4 py-3"
        >
          <v-row>
            <!-- Employé-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart1"
                :data="chart1"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- Client-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart2"
                :data="chart2"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- C1, C2-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart3"
                :data="chart3"
                :height="300"
                :width="300"
              />
            </v-col>
          </v-row>

          <v-row>
            <!-- Employé-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart4"
                :data="chart4"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- Client-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart5"
                :data="chart5"
                :height="300"
                :width="300"
              />
            </v-col>

            <!-- C1, C2-->
            <v-col
              cols="12"
              lg="4"
            >
              <area-chart
                v-if="chart6"
                :data="chart6"
                :height="300"
                :width="300"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Bar Chart-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="coloured-line"
          color="info"
          icon="mdi-chart-bar"
          class="px-4 py-3"
        >
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar1">
                  {{ graphBar1.header }}
                </h4>
              </div>
              <bar-chart
                v-if="graphBar1"
                :data="graphBar1"
              />
            </v-col>

            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar2">
                  {{ graphBar2.header }}
                </h4>
              </div>
              <bar-chart
                v-if="graphBar2"
                :data="graphBar2"
              />
            </v-col>

            <v-col
              cols="12"
              lg="4"
            >
              <div class="text-center">
                <h4 v-if="graphBar3">
                  {{ graphBar3.header }}
                </h4>
              </div>

              <bar-chart
                v-if="graphBar3"
                :data="graphBar3"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Line Chart-->
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card
          id="coloured-line"
          color="info"
          icon="mdi-chart-timeline-variant"
          class="px-4 py-3"
        >
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <div class="text-center">
                <h4 v-if="graphLine1">
                  {{ graphLine1.header }}
                </h4>
              </div>
              <line-chart
                v-if="graphLine1"
                :data="graphLine1"
              />
            </v-col>

            <v-col
              cols="12"
              lg="6"
            >
              <div class="text-center">
                <h4 v-if="graphLine2">
                  {{ graphLine2.header }}
                </h4>
              </div>
              <line-chart
                v-if="graphLine2"
                :data="graphLine2"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Components
  // Exemple https://codesandbox.io/s/93m1lpjrvr?file=/src/App.vue:32-44
  import AreaChart from '@/components/Chart/PieChart'
  import BarChart from '@/components/Chart/BarChart'
  import LineChart from '@/components/Chart/LineChart'
  // Service
  import DashboardService from '@/services/01Cell/DashboardService'

  export default {
    name: 'DashboardChart',
    components: {
      AreaChart,
      BarChart,
      LineChart,
    },
    data () {
      return {
        header: {},
        cardHeight: 120,
        chart1: null,
        chart2: null,
        chart3: null,
        chart4: null,
        chart5: null,
        chart6: null,
        graphBar1: null,
        graphBar2: null,
        graphBar3: null,
        graphLine1: null,
        graphLine2: null,
      }
    },
    async mounted () {
      // Start Spinner
      this.$loading.show()
      try {
        this.header = await DashboardService.header()

        DashboardService.graph().then(response => {
          this.chart1 = response.graph1.graph
          this.chart2 = response.graph2.graph
          this.chart3 = response.graph3.graph
          this.chart4 = response.graph4.graph
          this.chart5 = response.graph5.graph
          this.chart6 = response.graph6.graph
          // const datasetsChart1 = {
          //   title: {
          //     text: null,
          //     display: true,
          //   },
          //   textCenter: {
          //     display: false,
          //     title: null,
          //     text: null,
          //   },
          //   labels: [],
          //   datasets: [{
          //     backgroundColor: [],
          //     data: [],
          //   }],
          // }

          // // Graph1
          // datasetsChart1.title.text = this.$i18n.t(response.graph1.title).toUpperCase()
          // response.graph1.data.forEach(currentItem => {
          //   datasetsChart1.labels.push(`${this.$i18n.t(`dashboard.Type_employee.${currentItem.label}`)} (${currentItem.count})`)
          //   datasetsChart1.datasets[0].data.push(parseInt(currentItem.count, 10))
          //   datasetsChart1.datasets[0].backgroundColor.push(currentItem.color)
          // })

          // this.chart1 = datasetsChart1
        })

        DashboardService.graphBar().then(response => {
          this.graphBar1 = response.graphBar1.graph
          this.graphBar2 = response.graphBar2.graph
          this.graphBar3 = response.graphBar3.graph
        })

        DashboardService.graphLine().then(response => {
          this.graphLine1 = response.graphLine1.graph
          this.graphLine2 = response.graphLine2.graph
        })

        // this.chart1.datasets`
        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
